import decompress from "./gzdecompress.js";
import replace from "./seo-replace.js";

// save current pathname
var pathname = location.pathname;

window.load = function( url, options )
{
	NProgress.start();

	// merge options
	var request = $.extend( { url: url, dataType: 'html'}, options || {} );
	console.log( "LOAD", request );


	// send request
	return $.ajax(request).done( function( html, status, xhr ) {

		// get container from response
		var container = xhr.getResponseHeader('X-Container') || '#content';
		
		// get response title
		var responseTitle =  JSON.parse( xhr.getResponseHeader('X-Title') ) || 'Melchior';

		// get response url
		var responseURL = xhr.getResponseHeader('X-Response-Url');

		// set content
		$(container).html( html );

		// if container is a modal, open it
		if( container == '#modal' )
		{				
			var modal = bootstrap.Modal.getOrCreateInstance( "#modal" );
			if( modal._isShown ) 
			{
				modal.handleUpdate();
			}
			else 
			{
				modal.show();
			}
		}

		else // should be #content
		{
			// close modal
			bootstrap.Modal.getOrCreateInstance( "#modal" ).hide();
		
			// update title
			document.title = responseTitle;

			// push loaded href history state, if url changed
			if( responseURL != location.href )
			{
				history.pushState({ title: document.title }, '', responseURL);
			}

			// scroll to top if not popstate event (which will restore scroll position)
			if( request.trigger != 'popstate' && !xhr.getResponseHeader('X-No-Scroll') )
			{
				$(window).scrollTop(0);
			}


			// get, deflate and read x-seo header
			var seo = xhr.getResponseHeader('X-Seo');
			if( seo )
			{
				decompress( seo ).then( (result) => {
					replace(result);
				}).catch( (error) => {
					console.error( error );
				});
			}
		}

		// update pathname
		pathname = location.pathname;

		// trigger "navigated" event on document element
		document.dispatchEvent( new CustomEvent( 'navigated', { detail: { title: responseTitle, url: responseURL } } ) );

	}).fail( function( jqXHR, textStatus, errorThrown ) {
		$('#content').html( jqXHR.responseText );

		// scroll to top
		$(window).scrollTop(options.scroll || 0);

	}).always( function() {
		NProgress.done();
	});
}



$(function() {
	
	// check if window.load must be prevented
	var preventLoading = function( elt ) {
		
		var list = [ 'data-bs-toggle', 'data-bs-dismiss', 'download', 'target', 'data-favorite', 'data-prevent', 'data-fancybox' ];
		for( var i = 0; i < list.length; i++ )
		{
			if( elt.hasAttribute( list[i] ) ) return true;
		}

		var href = elt.getAttribute('href');
		if( href ) {

			// prevent if href match '#' (anchor)
			if( href.indexOf('#') == 0 ) return true;

			// prevent if href is javascript:
			if( href.indexOf('javascript:') == 0 ) return true;

			// prevent if href outside of current domain
			if( href.indexOf( window.location.origin ) !== 0 ) return true;
		}


		return false;
	}

	// catch all link click events, and load #content div with the href attribute
	$(document).on('click', 'a', function(e) {

		// prevent window.load is link has one of those attributes 
		if( preventLoading( this ) ) return;

		// prevent default click event
		e.preventDefault();

		// if data-method attribute is present, use it as form method
		if( $(this).data('method') )
		{
			var href = $(this).attr('href');
			var method = $(this).data('method') || 'post';

			$("#data-method").attr('action',href).attr('method',method).submit();
		}
		else
		{
			// href to load
			window.load( $(this).attr('href'), { trigger: 'click' } );
		}

	});


	// handle form submission
	$(document).on('submit', 'form', function(e) {

		if( preventLoading( this ) ) return;
		e.preventDefault();

		// get form method
		var method = $(this).attr('method') || 'get';

		// get form action
		var action = $(this).attr('action') || window.location.href;

		// create FormData object if method is not get
		var data = ( method == 'get' ) ? $(this).serialize() : new FormData(this);

		window.load( action, { trigger: "submit", method: method, data: data, processData: false, contentType: false } );
	});


	// catch all popstate events
	$(window).on('popstate', function(e) {
		e.preventDefault();

		// prevent if pathname is different
		if( pathname == location.pathname ) return;

		// load content
		window.load( location.href, { trigger: 'popstate' } );
	});
});