// seo-replace.js

const replace = function( tags ) {

	// remove all elements in head from <title> to <script type="application/ld+json">
	let elements = document.head.children;
	let i = 0;
	let remove = false;
	// let last_meta = null;
	while( i < elements.length ) {
		let element = elements[i];

		// start
		if( element.tagName == 'TITLE' ) {
			remove = true;
			element.remove();
			continue;
		}

		if( remove && element.tagName == 'META' ) {
			// console.log( 'remove', element );
			element.remove();
			continue;
		}
		
		// stop
		if( element.tagName == 'SCRIPT' && element.getAttribute('type') == 'application/ld+json' ) {
			element.remove();
			break;
		}

		i++;
		// last_meta = element;
	}


	// insert seo text into head
	let parser = new DOMParser();
	let doc = parser.parseFromString( '<html><head>'+tags+'</head></html>', 'text/html' );
	let head = doc.head;
	let children = head.children;
	for( let i = 0; i < children.length; i++ ) {

		// insert after last meta tag
		// if( last_meta ) {
		// 	// console.log( 'insert', children[i] );
		// 	document.head.insertBefore( children[i].cloneNode( true ), last_meta.nextSibling );
		// 	last_meta = children[i];
		// 	continue;
		// }

		// console.log( 'append', children[i] );
		document.head.appendChild( children[i].cloneNode( true ) );
	}
}


export default replace;
