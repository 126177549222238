// seo.js

const decompress = async (data) => {
  const ds = new DecompressionStream('deflate');

  const response = await fetch('data:application/octet-stream;base64,'+data);

  const blob_in = await response.blob();

  const stream_in = blob_in.stream().pipeThrough(ds);

  const reader = stream_in.getReader();

    let result = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      result += new TextDecoder().decode(value);
    }


    return await result;
};

export default decompress;




// decompress(
//   {{ Js::from( base64_encode( gzcompress( seo()->generate(), 9 ) ) ) }}
// ).then((result) => {
//   console.log(result);
// }).catch((error) => {
//   console.error(error);
// });